import { requestInstance } from '../../actions/axiosConfig';
export const autoLogin = async (role, setRole, user) => {
  const roleArr = ['guest', 'unsubs', 'subs'];

  const isJWT = document.cookie
    .split('; ')
    .find((row) => row.startsWith('LOGIN_HASH'));
  if (isJWT) {
    try {
      let res = user || (await requestInstance.get('/users/account'));
      if (res.success && res.user.subscription.status === 'Active') {
        setRole(roleArr[2]);
      } else if (res.success) {
        setRole(roleArr[1]);
      } else {
        setRole(roleArr[0]);
      }
      localStorage.setItem('user', JSON.stringify(res.user));
      console.log(res);
      console.log('auto login try +++++++++++++++++++++');
    } catch (error) {
      console.log(error);
      console.log('auto login catch +++++++++++++++++++++');

      setRole('guest');
    }
  } else {
    console.log('first');
    console.log('auto login +++++++++++++++++++++');

    setRole('guest');
  }
};

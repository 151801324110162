import React from 'react';

const EyesClose = () => {
  return (
    <div>
      {' '}
      <svg
        width='27'
        height='18'
        viewBox='0 0 30 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M15.2387 16.4766L10.584 12.825C10.9732 14.9138 12.7993 16.4986 15.0006 16.4986C15.084 16.5 15.159 16.4813 15.2387 16.4766ZM14.9997 18.75C11.2731 18.75 8.24965 15.7256 8.24965 12.0422C8.24965 11.724 8.30075 11.4197 8.30122 11.1131L3.80965 7.51875C2.97668 8.63203 2.25012 9.86438 1.66231 11.2027C1.57278 11.4094 1.45747 11.7656 1.45747 12C1.45747 12.2333 1.53073 12.5906 1.62008 12.7983C4.16304 18.5827 9.19508 22.4592 14.956 22.4592C17.087 22.4592 19.1171 21.9206 20.9607 20.9527L17.4685 18.2152C16.6918 18.5672 15.8481 18.75 14.9997 18.75ZM29.5684 21.9891L24.7168 18.187C26.1873 16.6889 27.4248 14.8706 28.3361 12.8386C28.4254 12.6309 28.4987 12.2728 28.4987 12.0403C28.4987 11.8071 28.4255 11.4497 28.3361 11.242C25.7931 5.45766 20.7611 1.58109 15.0001 1.58109C12.0615 1.58109 9.31887 2.60953 6.99387 4.37578L1.81887 0.239813C1.61262 0.0787031 1.36793 0 1.12606 0C0.79231 0 0.460857 0.148031 0.2397 0.430734C-0.144065 0.920109 -0.0588932 1.62745 0.430388 2.01042L28.1382 23.7182C28.6304 24.1028 29.3368 24.0151 29.7174 23.5273C30.145 23.0812 30.0559 22.3734 29.5684 21.9891ZM21.7075 12C21.7075 13.1648 21.3932 14.2491 20.8661 15.2002L19.059 13.7831C19.3047 13.2356 19.4572 12.6384 19.4572 11.9981C19.4572 9.55594 17.4416 7.49813 14.9994 7.49813C14.8916 7.4995 14.7381 7.51892 14.6028 7.53402C14.8497 7.96875 14.9575 8.46562 14.9575 9C14.9575 9.47672 14.8374 9.92203 14.6377 10.32L10.4809 7.02656C11.6743 5.93062 13.2522 5.24859 15.0006 5.24859C18.7262 5.25 21.7075 8.27812 21.7075 12Z'
          fill='#595B65'
        />
      </svg>
    </div>
  );
};

export default EyesClose;

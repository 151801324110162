import React from 'react';

const EyesOpen = () => {
  return (
    <svg
      width='27'
      height='18'
      viewBox='0 0 27 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M26.5387 8.31562C24.0249 3.35578 19.0483 0 13.35 0C7.65166 0 2.67368 3.35812 0.161287 8.31609C0.0552482 8.52821 0 8.76256 0 9.00023C0 9.23791 0.0552482 9.47226 0.161287 9.68438C2.67507 14.6442 7.65166 18 13.35 18C19.0483 18 24.0263 14.6419 26.5387 9.68391C26.6447 9.47179 26.7 9.23744 26.7 8.99977C26.7 8.76209 26.6447 8.52774 26.5387 8.31562ZM13.35 15.75C12.0298 15.75 10.7392 15.3541 9.64154 14.6124C8.54385 13.8707 7.68829 12.8165 7.18308 11.5831C6.67786 10.3497 6.54568 8.99251 6.80323 7.68314C7.06079 6.37377 7.69652 5.17103 8.63004 4.22703C9.56355 3.28302 10.7529 2.64015 12.0477 2.3797C13.3426 2.11925 14.6847 2.25292 15.9044 2.76381C17.1241 3.2747 18.1666 4.13987 18.9 5.2499C19.6335 6.35993 20.025 7.66498 20.025 9C20.0254 9.88654 19.853 10.7645 19.5177 11.5836C19.1824 12.4028 18.6908 13.1471 18.0709 13.7739C17.4509 14.4008 16.7149 14.898 15.9049 15.2371C15.0949 15.5761 14.2267 15.7504 13.35 15.75ZM13.35 4.5C12.9528 4.50561 12.5582 4.56537 12.1768 4.67766C12.4911 5.10969 12.642 5.64135 12.602 6.17622C12.562 6.71109 12.3337 7.21376 11.9587 7.59305C11.5836 7.97234 11.0865 8.20314 10.5576 8.24361C10.0286 8.28407 9.50289 8.13151 9.07566 7.81359C8.83237 8.71996 8.87629 9.68066 9.20122 10.5605C9.52616 11.4403 10.1157 12.1949 10.887 12.7181C11.6583 13.2413 12.5723 13.5068 13.5006 13.4771C14.4289 13.4475 15.3246 13.1242 16.0616 12.5528C16.7987 11.9814 17.34 11.1907 17.6094 10.2919C17.8787 9.39308 17.8626 8.43149 17.5632 7.54246C17.2639 6.65342 16.6963 5.88171 15.9405 5.33593C15.1847 4.79015 14.2787 4.49779 13.35 4.5Z'
        fill='#595B65'
      />
    </svg>
  );
};

export default EyesOpen;

import React from 'react';
import ufo from '../../assets/icons/Items.png';

const Empty = ({
  marginTop = 'mt-0',
  text = "You don't have any orders. Start selling and you will see your orders here.",
}) => {
  return (
    <div
      className={`${marginTop} bg-[rgba(255,255,255,1)] border border-solid border-[rgba(255,255,255,0.15)] h-[60vh] rounded-3xl w-full  flex  justify-center items-center gap-8 overflow-hidden`}
    >
      <div className='w-[15%]'>
        <img
          src={ufo}
          alt=''
          className='w-full h-full object-contain'
        />
      </div>
      <div className='w-[200px]'>
        <h2 className='text-[20px] font-bold'>No Records Found</h2>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default Empty;

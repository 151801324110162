import React from 'react';

const MailIcon = () => {
  return (
    <div>
      <svg
        width='29'
        height='19'
        viewBox='0 0 29 19'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M26.125 19H2.375C1.06519 19 0 17.9348 0 16.625V2.375C0 1.06519 1.06519 0 2.375 0H26.125C27.4348 0 28.5 1.06519 28.5 2.375V16.625C28.5 17.9348 27.4348 19 26.125 19ZM2.375 1.1875C1.72009 1.1875 1.1875 1.72009 1.1875 2.375V16.625C1.1875 17.2799 1.72009 17.8125 2.375 17.8125H26.125C26.7799 17.8125 27.3125 17.2799 27.3125 16.625V2.375C27.3125 1.72009 26.7799 1.1875 26.125 1.1875H2.375Z'
          fill='#85878C'
        />
        <path
          d='M14.25 12.5821L3.83384 5.84248C3.55834 5.66376 3.47938 5.29683 3.6575 5.02133C3.83563 4.74583 4.20316 4.66745 4.47866 4.84498L14.25 11.1678L24.0213 4.84498C24.2968 4.66745 24.6644 4.74583 24.8425 5.02133C25.0206 5.29683 24.9417 5.66376 24.6662 5.84248L14.25 12.5821Z'
          fill='#85878C'
        />
        <path
          d='M3.56307 15.4376C3.37129 15.4376 3.18247 15.345 3.06847 15.1734C2.88619 14.9003 2.95982 14.5321 3.23294 14.3498L8.57669 10.7873C8.84982 10.6051 9.21854 10.6787 9.40022 10.9518C9.5825 11.2249 9.50888 11.5931 9.23575 11.7753L3.892 15.3378C3.79047 15.4049 3.67588 15.4376 3.56307 15.4376Z'
          fill='#85878C'
        />
        <path
          d='M24.9369 15.4376C24.8241 15.4376 24.7095 15.4049 24.608 15.3378L19.2643 11.7753C18.9911 11.5931 18.9175 11.2249 19.0998 10.9518C19.2815 10.6787 19.6496 10.6051 19.9233 10.7873L25.2671 14.3498C25.5402 14.5321 25.6138 14.9003 25.4315 15.1734C25.3175 15.345 25.1287 15.4376 24.9369 15.4376Z'
          fill='#85878C'
        />
      </svg>
    </div>
  );
};

export default MailIcon;

const BellIcon = ({ className }) => {
  return (
    <svg
      width='24'
      height='26'
      viewBox='0 0 24 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M12 26C10.9391 26 9.92172 25.5786 9.17157 24.8284C8.42143 24.0783 8 23.0609 8 22C8 21.7348 8.10536 21.4804 8.29289 21.2929C8.48043 21.1054 8.73478 21 9 21H15C15.2652 21 15.5196 21.1054 15.7071 21.2929C15.8946 21.4804 16 21.7348 16 22C16 23.0609 15.5786 24.0783 14.8284 24.8284C14.0783 25.5786 13.0609 26 12 26ZM10.27 23C10.4458 23.3031 10.6982 23.5547 11.0018 23.7295C11.3054 23.9044 11.6496 23.9965 12 23.9965C12.3504 23.9965 12.6946 23.9044 12.9982 23.7295C13.3018 23.5547 13.5542 23.3031 13.73 23H10.27Z'
        fill='#908C99'
      />
      <path
        d='M14 4H10C9.73478 4 9.48043 3.89464 9.29289 3.70711C9.10536 3.51957 9 3.26522 9 3C9 2.20435 9.31607 1.44129 9.87868 0.87868C10.4413 0.316071 11.2044 0 12 0C12.7956 0 13.5587 0.316071 14.1213 0.87868C14.6839 1.44129 15 2.20435 15 3C15 3.26522 14.8946 3.51957 14.7071 3.70711C14.5196 3.89464 14.2652 4 14 4Z'
        fill='#908C99'
      />
      <path
        d='M23 23H1C0.734786 23 0.480432 22.8946 0.292896 22.7071C0.105359 22.5196 2.11107e-06 22.2652 2.11107e-06 22C-0.000874888 20.8707 0.271509 19.7579 0.793906 18.7566C1.3163 17.7553 2.0732 16.8953 3 16.25V11C2.99995 8.62341 3.93992 6.34327 5.61479 4.65716C7.28967 2.97105 9.56347 2.01584 11.94 2H12.05C14.4369 2 16.7261 2.94821 18.414 4.63604C20.1018 6.32387 21.05 8.61305 21.05 11V16.25C21.9676 16.9009 22.7148 17.7633 23.2283 18.7642C23.7418 19.7651 24.0065 20.8751 24 22C24 22.2652 23.8946 22.5196 23.7071 22.7071C23.5196 22.8946 23.2652 23 23 23ZM2.1 21H21.9C21.7584 20.3067 21.4712 19.6514 21.0575 19.0774C20.6437 18.5033 20.1129 18.0236 19.5 17.67C19.3474 17.5819 19.2208 17.455 19.133 17.3023C19.0452 17.1495 18.9993 16.9762 19 16.8V11C19 9.14348 18.2625 7.36301 16.9497 6.05025C15.637 4.7375 13.8565 4 12 4C10.1435 4 8.36301 4.7375 7.05025 6.05025C5.7375 7.36301 5 9.14348 5 11V16.8C5.0007 16.9762 4.95483 17.1495 4.86702 17.3023C4.77922 17.455 4.65261 17.5819 4.5 17.67C3.88711 18.0236 3.35626 18.5033 2.94253 19.0774C2.52881 19.6514 2.24164 20.3067 2.1 21Z'
        fill='#908C99'
      />
      <circle
        cx='19'
        cy='5'
        r='3'
        fill='#FF0000'
      />
    </svg>
  );
};

export default BellIcon;

import React from 'react';

const Lock = () => {
  return (
    <div>
      <svg
        width='14'
        height='19'
        viewBox='0 0 14 19'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M12.6535 8.21086H11.4635V5.67223C11.4635 3.21294 9.44054 1.18998 6.98125 1.18998C4.52196 1.18998 2.499 3.21294 2.499 5.67223V8.21086H1.30902V5.67223C1.30902 2.53862 3.84764 0 6.98125 0C10.1149 0 12.6535 2.53862 12.6535 5.67223V8.21086Z'
          fill='#85878C'
        />
        <path
          d='M12.0585 19H1.90397C0.872659 19 0 18.1273 0 17.096V9.48013C0 8.44882 0.872659 7.57617 1.90397 7.57617H12.0585C13.0898 7.57617 13.9624 8.44882 13.9624 9.48013V17.096C13.9624 18.167 13.0898 19 12.0585 19ZM1.90397 8.84548C1.54698 8.84548 1.26932 9.12314 1.26932 9.48013V17.096C1.26932 17.453 1.54698 17.7307 1.90397 17.7307H12.0585C12.4155 17.7307 12.6931 17.453 12.6931 17.096V9.48013C12.6931 9.12314 12.4155 8.84548 12.0585 8.84548H1.90397Z'
          fill='#85878C'
        />
        <path
          d='M8.25053 11.3842C8.25053 12.0982 7.69521 12.6535 6.98122 12.6535C6.26724 12.6535 5.71191 12.0982 5.71191 11.3842C5.71191 10.6702 6.26724 10.1149 6.98122 10.1149C7.69521 10.1149 8.25053 10.7099 8.25053 11.3842Z'
          fill='#85878C'
        />
        <path
          d='M7.29855 11.3842H6.66389L6.02924 15.1921H7.9332L7.29855 11.3842Z'
          fill='#85878C'
        />
      </svg>
    </div>
  );
};

export default Lock;

import React from 'react';

const PhoneIcon = () => {
  return (
    <div>
      <svg
        width='19'
        height='19'
        viewBox='0 0 19 19'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_54_3490)'>
          <path
            d='M11.4824 13.3958C11.4824 13.3958 13.021 12.515 13.4238 12.3033C13.8255 12.0909 14.2416 12.0373 14.4943 12.1916C14.877 12.4257 18.0902 14.5632 18.3608 14.7524C18.6314 14.942 18.7617 15.4835 18.3897 16.014C18.0192 16.5445 16.3099 18.6421 15.5857 18.6197C14.8602 18.5961 11.8442 18.53 6.15671 12.841C0.470391 7.15392 0.403131 4.1371 0.379951 3.41168C0.356771 2.68664 2.45437 0.977017 2.98485 0.606137C3.51609 0.235637 4.05835 0.375097 4.24683 0.635777C4.46115 0.932557 6.57395 4.13558 6.80651 4.50266C6.96573 4.75346 6.90721 5.17184 6.69479 5.57388C6.48351 5.97668 5.60267 7.5153 5.60267 7.5153C5.60267 7.5153 6.22359 8.57436 8.32347 10.6739C10.4237 12.7737 11.4824 13.3958 11.4824 13.3958Z'
            stroke='#85878C'
            stroke-miterlimit='10'
          />
        </g>
        <defs>
          <clipPath id='clip0_54_3490'>
            <rect
              width='19'
              height='19'
              fill='white'
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default PhoneIcon;

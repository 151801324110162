import React from 'react';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import Unsubscribed from '../pages/Unsubscribed';
import UnsubscribedForm from '../pages/UnsubscribedForm';
import PaymentPending from '../pages/unsubscribedPending';
import PaymentSuccess from '../pages/SubscriptionSucceded';
import Logout from '../pages/Logout';
import { element } from 'prop-types';
import { autoLogin } from '../utils/helper/AutoLogin';
import { useRole } from '../Context/RoleContext';
import Error from '../pages/Error';

const UnsubsRoutes = () => {
  const { role, setRole } = useRole();
  const route = createBrowserRouter([
    {
      path: '/',
      element: (
        <Navigate
          to='unsubscribed'
          replace
        />
      ),
      errorElement: <Error />,
    },
    {
      path: 'unsubscribed',
      element: <Unsubscribed />,
    },
    {
      path: '/unsubscribed/form/:id',
      element: <UnsubscribedForm />,
    },
    {
      path: '/unsubscribed/pendingPayment',
      element: <PaymentPending />,
    },

    {
      path: '/logout',
      element: <Logout />,
    },
  ]);
  return <RouterProvider router={route} />;
};

export default UnsubsRoutes;

import React from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { createContext } from 'react';
const RoleContext = createContext(null);
export const RoleProvider = ({ children }) => {
  const [role, setRole] = useState(localStorage.getItem('role') || 'guest');
  return (
    <RoleContext.Provider value={{ role, setRole }}>
      {children}
    </RoleContext.Provider>
  );
};

export const useRole = () => {
  return useContext(RoleContext);
};

import React from 'react';

const User = () => {
  return (
    <div>
      <svg
        width='17'
        height='19'
        viewBox='0 0 17 19'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M8.14288 9.5C9.08234 9.5 10.0007 9.22142 10.7818 8.69948C11.563 8.17755 12.1718 7.4357 12.5313 6.56775C12.8908 5.6998 12.9849 4.74473 12.8016 3.82332C12.6183 2.90191 12.1659 2.05554 11.5016 1.39124C10.8373 0.726945 9.99097 0.274552 9.06956 0.091272C8.14815 -0.0920077 7.19308 0.00205809 6.32514 0.361574C5.45719 0.72109 4.71534 1.32991 4.1934 2.11104C3.67147 2.89218 3.39288 3.81054 3.39288 4.75C3.39288 6.00978 3.89333 7.21796 4.78413 8.10876C5.67492 8.99956 6.8831 9.5 8.14288 9.5ZM8.14288 1.35714C8.81393 1.35714 9.4699 1.55613 10.0279 1.92894C10.5858 2.30176 11.0207 2.83165 11.2775 3.45161C11.5343 4.07157 11.6015 4.75377 11.4705 5.41191C11.3396 6.07006 11.0165 6.67461 10.542 7.14911C10.0675 7.62361 9.46295 7.94675 8.8048 8.07767C8.14665 8.20858 7.46446 8.14139 6.84449 7.88459C6.22453 7.6278 5.69464 7.19292 5.32183 6.63497C4.94901 6.07702 4.75003 5.42104 4.75003 4.75C4.75003 3.85016 5.10749 2.98717 5.74377 2.35089C6.38005 1.71461 7.24304 1.35714 8.14288 1.35714Z'
          fill='#85878C'
        />
        <path
          d='M8.82143 10.8571H7.46428C5.48463 10.8571 3.58606 11.6435 2.18624 13.0434C0.786414 14.4432 0 16.3417 0 18.3214C0 18.5014 0.0714923 18.674 0.198749 18.8012C0.326006 18.9285 0.498603 19 0.678571 19H15.6071C15.7871 19 15.9597 18.9285 16.087 18.8012C16.2142 18.674 16.2857 18.5014 16.2857 18.3214C16.2857 16.3417 15.4993 14.4432 14.0995 13.0434C12.6996 11.6435 10.8011 10.8571 8.82143 10.8571ZM1.39786 17.6428C1.56471 16.1504 2.2756 14.7718 3.39467 13.7704C4.51375 12.769 5.96256 12.215 7.46428 12.2143H8.82143C10.3231 12.215 11.772 12.769 12.891 13.7704C14.0101 14.7718 14.721 16.1504 14.8879 17.6428H1.39786Z'
          fill='#85878C'
        />
      </svg>
    </div>
  );
};

export default User;

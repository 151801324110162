import React from 'react';

const Eye = ({ eyeColor }) => {
  return (
    <svg
      width='23'
      height='13'
      viewBox='0 0 23 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.375 0C7.21602 0 3.97617 2.47813 0 6.5C3.42266 9.93789 6.29688 13 11.375 13C16.448 13 20.1805 9.12031 22.75 6.57109C20.1195 3.58516 16.3922 0 11.375 0ZM11.375 11.1363C8.86641 11.1363 6.825 9.0543 6.825 6.5C6.825 3.94062 8.86641 1.86367 11.375 1.86367C13.8836 1.86367 15.925 3.9457 15.925 6.5C15.925 9.05937 13.8836 11.1363 11.375 11.1363Z'
        fill={eyeColor ? eyeColor : '#504D4D'}
      />
      <path
        d='M11.3746 4.8751C11.3746 4.47393 11.5219 4.1083 11.7605 3.82393C11.6336 3.80361 11.5066 3.79346 11.3746 3.79346C9.91211 3.79346 8.71875 5.00713 8.71875 6.5001C8.71875 7.99307 9.91211 9.20674 11.3746 9.20674C12.8371 9.20674 14.0305 7.99307 14.0305 6.5001C14.0305 6.3833 14.0203 6.2665 14.0102 6.14971C13.7309 6.36807 13.3855 6.5001 13.0047 6.5001C12.1008 6.5001 11.3746 5.77393 11.3746 4.8751Z'
        fill={eyeColor ? eyeColor : '#504D4D'}
      />
    </svg>
  );
};

export default Eye;

import React from 'react';

const Purchase = () => {
  return (
    <svg
      width='46'
      height='35'
      viewBox='0 0 46 35'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M40.25 35H5.25C3.85761 35 2.52226 34.4469 1.53769 33.4623C0.553124 32.4777 0 31.1424 0 29.75V5.25C0 3.85761 0.553124 2.52226 1.53769 1.53769C2.52226 0.553124 3.85761 0 5.25 0H40.25C41.6424 0 42.9777 0.553124 43.9623 1.53769C44.9469 2.52226 45.5 3.85761 45.5 5.25V29.75C45.5 31.1424 44.9469 32.4777 43.9623 33.4623C42.9777 34.4469 41.6424 35 40.25 35ZM5.25 3.5C4.78587 3.5 4.34075 3.68437 4.01256 4.01256C3.68437 4.34075 3.5 4.78587 3.5 5.25V29.75C3.5 30.2141 3.68437 30.6593 4.01256 30.9874C4.34075 31.3156 4.78587 31.5 5.25 31.5H40.25C40.7141 31.5 41.1592 31.3156 41.4874 30.9874C41.8156 30.6593 42 30.2141 42 29.75V5.25C42 4.78587 41.8156 4.34075 41.4874 4.01256C41.1592 3.68437 40.7141 3.5 40.25 3.5H5.25Z'
        fill='white'
      />
      <path
        d='M19.2498 28H8.74976C8.28563 28 7.84051 27.8156 7.51232 27.4874C7.18413 27.1592 6.99976 26.7141 6.99976 26.25C6.99976 25.7859 7.18413 25.3408 7.51232 25.0126C7.84051 24.6844 8.28563 24.5 8.74976 24.5H19.2498C19.7139 24.5 20.159 24.6844 20.4872 25.0126C20.8154 25.3408 20.9998 25.7859 20.9998 26.25C20.9998 26.7141 20.8154 27.1592 20.4872 27.4874C20.159 27.8156 19.7139 28 19.2498 28Z'
        fill='white'
      />
      <path
        d='M43.75 12.25H1.75C1.28587 12.25 0.840752 12.0656 0.512563 11.7374C0.184374 11.4092 0 10.9641 0 10.5C0 10.0359 0.184374 9.59075 0.512563 9.26256C0.840752 8.93437 1.28587 8.75 1.75 8.75H43.75C44.2141 8.75 44.6592 8.93437 44.9874 9.26256C45.3156 9.59075 45.5 10.0359 45.5 10.5C45.5 10.9641 45.3156 11.4092 44.9874 11.7374C44.6592 12.0656 44.2141 12.25 43.75 12.25Z'
        fill='white'
      />
    </svg>
  );
};

export default Purchase;

import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
} from '@tanstack/react-table';
import mdata from '../../utils/Data/MOCK_DATA (2).json';
import { useMemo } from 'react';
const BasicTable = () => {
  const data = useMemo(() => mdata, []);
  const columns = [
    {
      header: 'ID',
      accessorKey: 'id',
    },
    {
      header: 'First Name',
      accessorKey: 'first_name',
    },
    {
      header: 'Last Name',
      accessorKey: 'last_name',
    },
    {
      header: 'Email',
      accessorKey: 'email',
    },
    {
      header: 'Gender',
      accessorKey: 'gender',
    },
  ];
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });
  return (
    <div>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className='flex justify-between w-1/2 mt-4'>
        <button
          className='bg-white rounded-lg border border-black'
          onClick={() => table.setPageIndex(0)}
        >
          First Page
        </button>
        <button
          className='bg-white rounded-lg border border-black'
          disabled={!table.getCanPreviousPage()}
          onClick={() => table.previousPage()}
        >
          Prev Page
        </button>
        <button
          className='bg-white rounded-lg border border-black'
          disabled={!table.getCanNextPage()}
          onClick={() => table.nextPage()}
        >
          next Page
        </button>
        <button
          className='bg-white rounded-lg border border-black'
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
        >
          last Page
        </button>
      </div>
    </div>
  );
};
export default BasicTable;

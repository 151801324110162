import ProfileCommon from "./components/ProfileCommon"

const Index = () => {
  return (
    <>
    <ProfileCommon/>
    </>
  )
}

export default Index
import axios from "axios";
import { APP_ENVIRONMENT } from "../constant";
import { autoLogin } from "../utils/helper/AutoLogin";
import { window } from "fontawesome";
import { toast } from "react-toastify";
export let BASE_ENDPOINT = "";
if (APP_ENVIRONMENT === "local") {
  BASE_ENDPOINT = "http://localhost:3000/api/v1/";
} else if (APP_ENVIRONMENT === "development") {
  BASE_ENDPOINT = "https://api.dev.myzer.ai/api/v1";
} else if (APP_ENVIRONMENT === "staging") {
  BASE_ENDPOINT = "https://api.staging.myzer.ai/api/v1";
} else if (APP_ENVIRONMENT === "production") {
  BASE_ENDPOINT = "https://api-live.myzer.in/api/v1";
}

export const requestInstance = axios.create({
  baseURL: BASE_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
  },
});
export const reqInstanceImage = axios.create({
  baseURL: BASE_ENDPOINT,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});
// Error handling interceptor
requestInstance.interceptors.response.use(
  (response) => {
    // Successful response (status 2xx)
    return response.data;
  },
  (error) => {
    // Error response (status 4xx or 5xx)
    if (error.response) {
      const { status, data } = error.response;

      // Client-side errors (4xx)
      if (status >= 400 && status < 500) {
        console.log("Client error:", data.message);
        if (status === 401) {
          toast.error("Unauthorized! Logging Out...");
          const isJWT = document.cookie
            .split("; ")
            .find((row) => row.startsWith("LOGIN_HASH"));
          if (!isJWT) {
            document.location.replace("/");
          }
        }
        // You can perform actions like displaying an error message to the user
      }

      // Server-side errors (5xx)
      if (status >= 500 && status < 600) {
        console.log("Server error:", data.message);
        // You can handle server errors differently, e.g., retry the request
      }
    } else if (error.request) {
      // No response received (e.g., network error)
      console.log("No response:", error.request);
      // You can handle this scenario, e.g., show a network error message
    } else {
      // Other errors
      console.log("Error:", error.message);
      // You can handle other types of errors, e.g., show a generic error message
    }

    // Reject the promise so that the caller can handle the error further
    return Promise.reject(error);
  }
);
reqInstanceImage.interceptors.response.use(
  (response) => {
    // Successful response (status 2xx)
    return response.data;
  },
  (error) => {
    // Error response (status 4xx or 5xx)
    if (error.response) {
      const { status, data } = error.response;

      // Client-side errors (4xx)
      if (status >= 400 && status < 500) {
        console.log("Client error:", data.message);
        // You can perform actions like displaying an error message to the user
      }

      // Server-side errors (5xx)
      if (status >= 500 && status < 600) {
        console.log("Server error:", data.message);
        // You can handle server errors differently, e.g., retry the request
      }
    } else if (error.request) {
      // No response received (e.g., network error)
      console.log("No response:", error.request);
      // You can handle this scenario, e.g., show a network error message
    } else {
      // Other errors
      console.log("Error:", error.message);
      // You can handle other types of errors, e.g., show a generic error message
    }

    // Reject the promise so that the caller can handle the error further
    return Promise.reject(error);
  }
);

import React from 'react';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import AppBackground from '../pages/AppBackground';
import Dashboard from '../pages/Dashboard';
import LandingPage from '../pages/LandingPage/index';
import Transaction from '../pages/Transaction';
import Domain from '../pages/Domain';
import PaymentGateway from '../pages/PaymentGateways/Index';
import UserAccount from '../pages/UserAccount/Index';
import OtherSettings from '../pages/OtherSettings/Index';
import Subscription from '../pages/Subscription/Index';
import CreateLandingPage from '../pages/LandingPage/components/CreateLandingPage';
import Editor from '../pages/LandingPage/Editor/Editor';
import Logout from '../pages/Logout';
import { useRole } from '../Context/RoleContext';
import { autoLogin } from '../utils/helper/AutoLogin';
import Error from '../pages/Error';
import PaymentSuccess from '../pages/SubscriptionSucceded';
import AddNewDomain from '../pages/Domain/components/AddNewDomain';

const SubsRoutes = () => {
  const { role, setRole } = useRole();
  const route = createBrowserRouter([
    {
      path: '/',
      element: <AppBackground />,
      errorElement: <Error />,
      children: [
        {
          index: true,
          element: (
            <Navigate
              replace
              to={'dashboard'}
            />
          ),
        },
        {
          path: 'dashboard',
          element: <Dashboard />,
        },
        {
          path: 'landing-pages',
          element: <LandingPage />,
        },
        {
          path: 'transactions',
          element: <Transaction />,
        },
        {
          path: 'domain',
          element: <Domain />,
        },

        {
          path: 'payment-gateways',
          element: <PaymentGateway />,
        },
        {
          path: 'payment-gateways/:method',
          element: <PaymentGateway />,
        },
        {
          path: 'account',
          element: <UserAccount />,
        },
        {
          path: 'subscription',
          element: <Subscription />,
        },
        {
          path: 'other-settings',
          element: <OtherSettings />,
        },
      ],
    },
    {
      path: '/landing-pages/create_landing_page',
      element: <CreateLandingPage />,
    },
    {
      path: 'domain/:method',
      element: <AddNewDomain />,
    },
    {
      path: ':pageType/editor/:id',
      element: <Editor />,
    },
    {
      path: '/logout',
      element: <Logout />,
    },
    {
      path: '/unsubscribed/successPayment',
      element: <PaymentSuccess />,
    },
  ]);
  return <RouterProvider router={route} />;
};

export default SubsRoutes;

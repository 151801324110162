import React from 'react';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import Accounts from '../pages/Accounts/Accounts';
import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import Verify from '../pages/SignUp/components/Verify';
import ForgetPassword from '../pages/ForgetPassword/index';
import ForgetPasswordEmail from '../pages/ForgetPassword/components/ForgetForm';
import ForgetPasswordOTP from '../pages/ForgetPassword/components/ForgetFormOTP';
import Logout from '../pages/Logout';
import { useContext } from 'react';
import Error from '../pages/Error';

const GuestRoutes = () => {
  const route = createBrowserRouter([
    {
      path: '/',
      element: <Accounts />,
      errorElement: <Error />,
      children: [
        {
          index: true,
          element: (
            <Navigate
              replace
              to={'signin'}
            />
          ),
        },
        {
          path: 'signin',
          element: <SignIn />,
        },
        {
          path: '/signup',
          element: <SignUp />,
        },
        {
          path: '/verify',
          element: <Verify />,
        },
        {
          path: 'forgetPassword',
          element: <ForgetPassword />,
          children: [
            {
              index: true,
              element: <ForgetPasswordEmail />,
            },
            {
              path: 'otp',
              element: <ForgetPasswordOTP />,
            },
          ],
        },
      ],
    },
  ]);
  return <RouterProvider router={route} />;
};

export default GuestRoutes;

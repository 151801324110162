import React from 'react';
import AccountsError from './components/AccountsError';
import { Navigate, useRouteError } from 'react-router-dom';

const Error = () => {
  const error = useRouteError();
  console.log(error, 'adas');
  return (
    <Navigate
      to='/'
      replace
    />
  );
};

export default Error;

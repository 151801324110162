// import { useEffect } from 'react';
import { createContext, useState } from 'react';
import './App.css';
import { useAutoLoginData } from './actions/User/Login';
// import { useAutoLoginData } from './actions/User/Login';
import Router from './routes/Routes';
import { QueryClient, QueryClientProvider, useQueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import GuestRoutes from './routes/GuestRoutes';
import UnsubsRoutes from './routes/UnsubsRoutes';
import SubsRoutes from './routes/SubsRoutes';
import { requestInstance } from './actions/axiosConfig';
import { RoleProvider, useRole } from './Context/RoleContext';
import { useContext } from 'react';
import { autoLogin } from './utils/helper/AutoLogin';
// export const APP_ENVIRONMENT = 'local';

function App() {
  const roleArr = ['guest', 'unsubs', 'subs'];
  const { role, setRole } = useRole();

  console.log(role);
  useEffect(() => {
    localStorage.setItem('role', role);
    autoLogin(role, setRole);
  }, [role]);
  return (
    <>
      {role === roleArr[0] && <GuestRoutes />}
      {role === roleArr[1] && <UnsubsRoutes />}
      {role === roleArr[2] && <SubsRoutes />}
    </>
  );
}

export default App;

import React from 'react';
import Sidebar from './components/Sidebar';
import Logo from '../../assets/logos/logo.png';
import bg from '../../assets/images/signInRightBg.png';

const PaymentSuccess = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  console.log(user);
  const email = user.email;
  const phone = user.phone;
  const payId = user.subscription.paymentDetails.paymentId;
  const plan = user.subscription.planName;

  return (
    <div className=' w-full h-screen bg-[#0A1118] flex gap-20 relative'>
      <img
        src={bg}
        alt=''
        className='absolute opacity-10 -bottom-1/4 right-0'
      />
      <div className='absolute z-20 text-[4rem] top-1/4 right-[15%] rotate-[30deg]'>
        🤩
      </div>
      <div className='absolute z-20 text-[3rem] bottom-1/4 left-[25%] rotate-[-30deg]'>
        😍
      </div>
      <Sidebar />
      <div className='w-full h-full z-10 relative'>
        <div className='w-[30%]'>
          <img
            src={Logo}
            alt='myzer'
          />
        </div>
        <div className='flex justify-center items-center flex-col gap-[5rem]  mr-20'>
          <h1 className='capitalize text-[70px] text-white font-bold'>
            welcome{' '}
            <span className='bg-gradient-orange-text bg-clip-text text-transparent'>
              onboard
            </span>
          </h1>
          <div className='bg-white rounded-lg grid grid-cols-4 w-1/2 p-2'>
            <p className='font-bold text-black col-span-1 text-center border-r border-r-[#ddd] border-b border-b-[#ddd] p-3'>
              Email
            </p>
            <p className='text-black col-span-3 text-center border-b border-b-[#ddd] p-3'>
              {email}
            </p>
            <p className='font-bold text-black col-span-1 text-center  border-r border-r-[#ddd] border-b border-b-[#ddd] p-3'>
              Phone
            </p>
            <p className='text-black col-span-3 text-center border-b border-b-[#ddd] p-3'>
              {phone}
            </p>
            <p className='font-bold text-black col-span-1 text-center  border-r border-r-[#ddd] border-b border-b-[#ddd] p-3'>
              Pay. ID
            </p>
            <p className='text-black col-span-3 text-center border-b border-b-[#ddd] p-3'>
              {payId}
            </p>
            <p className='font-bold text-black col-span-1 text-center  border-r border-r-[#ddd] p-3'>
              Plan
            </p>
            <p className='text-black col-span-3 text-center p-3'>{plan}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
